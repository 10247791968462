import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ChevronDown from '@/public/icons/chevron-down.svg';
import { RoutingContext } from '@/contexts/routing-context';
import ClickAway from '../click-away/click-away';
import MenuDropdown from '../menu-dropdown/menu-dropdown';

export default function UserMenuDropdown({
  userName, menuItems, dropdownDisabled, onClickAway,
}) {
  const { shouldRenderV2Navigation } = useContext(RoutingContext);
  const [isOpen, setIsOpen] = useState(false);
  const avatarLetters = () => {
    const [firstName, lastName] = userName?.split(' ').filter(Boolean);
    const lastNameString = lastName ? lastName[0] : '';
    return `${firstName[0]}${lastNameString}`;
  };

  const onKeyDown = (func) => (event) => {
    if (event.key === 'Enter' && func) {
      func();
    }
  };

  const toggleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleOnClickAway = () => {
    setIsOpen(false);
    onClickAway();
  };

  if (shouldRenderV2Navigation) {
    const isDropdownEnabled = !dropdownDisabled;
    return (
      <ClickAway
        shouldWeListen={isDropdownEnabled && isOpen}
        onClickAway={handleOnClickAway}
      >
        <div className="container">
          <div
            className="user"
            onClick={toggleOpen}
            onKeyDown={onKeyDown(toggleOpen)}
            role="button"
            tabIndex={0}
          >
            <span>{avatarLetters()}</span>
            {isDropdownEnabled && (
              <div className="arrow-container">
                <div className={`arrow-down ${isOpen ? 'open' : ''}`}><ChevronDown /></div>
              </div>
            )}
          </div>
          {isDropdownEnabled && isOpen && (
            <div className="items">
              <MenuDropdown
                menuItems={menuItems}
                onKeyDown={onKeyDown}
                closeMenu={() => setIsOpen(false)}
                isOpen={isOpen}
              />
            </div>
          )}
          <style jsx>
            {`
            .container {
              position: relative;
            }
            .user {
              display: flex;
              align-items: center;
              background: none;
              cursor: pointer;
              border: none;
            }

            .user:disabled {
              cursor: default;
            }

            .user span {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 32px;
              width: 32px;
              border-radius: 50%;
              background-color: #282D32;
              color: #fff;
              font-size: 14px;
              line-height: 24px;
              font-weight: 500;
              text-transform: uppercase;
            }

            .items {
              position: absolute;
              top: 40px;
              right: 0;
              z-index: 1;
            }

            .arrow-down {
              transition: transform .24s;
            }

            .arrow-down.open {
              transform: rotate(180deg);
            }
          `}
          </style>
        </div>
      </ClickAway>
    );
  }

  return (
    <ClickAway
      shouldWeListen={isOpen}
      onClickAway={handleOnClickAway}
    >
      <div className="container">

        {dropdownDisabled
          ? (
            <div className="menu-bar disabled">
              <div className="avatar">{avatarLetters()}</div>
            </div>
          )
          : (
            <div
              className={`menu-bar${isOpen ? ' open' : ''}`}
              onClick={toggleOpen}
              onKeyDown={onKeyDown(toggleOpen)}
              role="button"
              tabIndex={0}
            >
              <div className="avatar">{avatarLetters()}</div>
              <div className="arrow-container">
                <div id="user-menu-chevron" className={`arrow-down ${isOpen ? 'open' : ''}`}><ChevronDown /></div>
              </div>

            </div>
          )}

        <MenuDropdown
          menuItems={menuItems}
          onKeyDown={onKeyDown}
          closeMenu={() => setIsOpen(false)}
          isOpen={isOpen}
        />
        <style jsx>
          {`

          .arrow-container {
            font-size: 12px;
            padding: 5px 0 0 5px;
          }

          .arrow-down {
            transition: transform .24s;
          }

          .arrow-down.open {
            transform: rotate(180deg);
          }

          .container {
            position: relative;
            width: 175px;
            font-size: 16px;
            font-family: var(--font-family-body);
          }

          .menu-bar {
            display: flex;
            flex-direction: row;
            float: right;
            width: 80px;
            height: 50px;
            padding: 5px;
            cursor: pointer;
            position: relative;
            z-index: var(--zindex-dropdown);
          }

          .menu-bar:before {
            display: block;
            content: ' ';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -4px;
            background-color: #fff;
            height: 7px;
          }

          .menu-bar.open {
            box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 1px;
          }

          .avatar {
            min-width: 44px;
            height: 36px;
            border-radius: var(--border-radius-small);
            background-color: #222;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            text-transform: uppercase;
            font-family: var(--font-family-heading);
          }

          .disabled {
            cursor: default;
          }
        `}
        </style>
      </div>
    </ClickAway>
  );
}

UserMenuDropdown.defaultProps = {
  dropdownDisabled: false,
  onClickAway: () => { },
};

UserMenuDropdown.propTypes = {
  userName: PropTypes.string.isRequired,
  dropdownDisabled: PropTypes.bool,
  onClickAway: PropTypes.func,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  })).isRequired,
};
