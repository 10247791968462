import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LocaleContext } from '@/contexts/locale-context';
import { useRouter } from 'next/router';
import Link from 'next/link';
import getLinkUrl from '@/utilities/getNavigationURL';
import { AdminContext } from '@/contexts/admin-context';

function SectionSubpageItem({ page }) {
  const router = useRouter();

  const isActive = router.pathname.includes(page.url);
  const { translate } = useContext(LocaleContext);
  const { isAdmin, adminModeMerchantId, isAdminReviewMode } = useContext(AdminContext);

  const linkURL = useMemo(() => getLinkUrl({
    configUrl: page.url,
    merchantId: router.query.companyId,
    adminModeMerchantId,
    isAdmin: isAdmin && !isAdminReviewMode,
    isAdminReviewMode,
  }), [page.url, router.query.companyId, adminModeMerchantId, isAdmin, isAdminReviewMode]);

  return (
    <>
      <Link href={linkURL} passHref>
        <div className={`page ${isActive ? 'active' : ''}`} href="navigation-href">
          <span>{translate(page.title)}</span>
        </div>
      </Link>
      <style jsx>
        {`
        .page {
            padding: 4px 8px;
            border-radius: 2px;
            cursor: pointer;
            display: block;
          }

          .page:hover {
            background: #F9FAFB;
          }

          .page.active {
            background: #F4F6F8;
          }

          .page span {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #767676;
          }
        `}
      </style>
    </>
  );
}

SectionSubpageItem.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default SectionSubpageItem;
